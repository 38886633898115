/** ComplianceExpertMainPage.js **/
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Box, CircularProgress, Snackbar, Alert } from "@mui/material"
import Sidebar from "../../components/SideBar/Sidebar"
import NewAlert from "./components/NewAlert"
import EmptyBox from "./components/EmptyBox"
import { useOutletContext } from "react-router-dom"
import styles from "./MainPage.module.scss"
import CaseReportDialog from "./components/CaseReportDialog"
import FilesAttachments from "./components/FilesAttachments"
import { uploadCSVReport } from "../../services/main/mainServices"
import KanbanBoard from "./components/CasesTableExpert/KanbanBoard"
import { updateCaseInfo } from "../../services/notebook/notebookServices"

const ComplianceExpertMainPage = () => {
    const [
        control,
        values,
        triggerFetchMainPageData,
        setTriggerFetchMainPageData,
        loadMainPageData,
        isInitialLoading,
        handleFinalizeReportStatus,
    ] = useOutletContext()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [openNewAlertPopup, setOpenNewAlertPopup] = useState(false)
    const [welcomeState, setWelcomeState] = useState(false)
    const [sections, setSections] = useState([])

    const [displayedReportId, setDisplayedReportId] = useState(null)
    const [caseReportDialogOpen, setCaseReportDialogOpen] = useState(false)
    const [auditTrailPreview, setAuditTrailPreview] = useState("<div/>")

    const [filesModalOpen, setFilesModalOpen] = useState(false)
    const [selectedFilesCaseId, setSelectedFilesCaseId] = useState(null)

    // Unified toast state
    const [toast, setToast] = useState({
        open: false,
        severity: "info", // "success" | "error" | "warning" | "info"
        message: "",
    })

    const showToast = (severity, message) => {
        setToast({ open: true, severity, message })
    }

    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setToast({ ...toast, open: false })
    }

    // Wrap parent's finalize function to show toast on success/fail
    const handleFinalizeReportStatusWrapper = async (reportId, isApproved) => {
        try {
            await handleFinalizeReportStatus(reportId, isApproved)
            showToast(
                "success",
                isApproved ? "Report Approved and Moved to Done" : "Report Sent Back for Revision",
            )
        } catch (err) {
            console.error(err)
            showToast("error", "An error occurred finalizing the report: " + err.message)
        } finally {
            loadMainPageData()
        }
    }

    // CSV upload
    const handleUploadCSV = async (reportRequestId, csvJson) => {
        try {
            await uploadCSVReport(reportRequestId, csvJson, dispatch, refreshAccessTokenCounter)
            showToast("success", `Successfully uploaded CSV for card ID: ${reportRequestId}`)
            loadMainPageData()
        } catch (err) {
            console.error(err)
            showToast("error", "Error uploading CSV: " + err.message)
        }
    }

    // For setting "No data" or "Welcome" states
    useEffect(() => {
        if (
            !isInitialLoading &&
            (values?.data_collection?.length ?? 0) === 0 &&
            (values?.resolutions?.length ?? 0) === 0 &&
            (values?.final_review?.length ?? 0) === 0 &&
            (values?.done?.length ?? 0) === 0
        ) {
            setWelcomeState(true)
        } else {
            setWelcomeState(false)
        }

        setSections([
            {
                controllerName: "data_collection",
                headerTitle: "Data Collection",
                emptyText: "No data collection reports",
                cases: values?.data_collection || [],
            },
            {
                controllerName: "resolutions",
                headerTitle: "Resolutions",
                emptyText: "No reports in resolutions",
                cases: values?.resolutions || [],
            },
            {
                controllerName: "final_review",
                headerTitle: "Final Review",
                emptyText: "No reports waiting for final review",
                cases: values?.final_review || [],
            },
            {
                controllerName: "done",
                headerTitle: "Done",
                emptyText: "No completed reports yet",
                cases: values?.done || [],
            },
        ])
    }, [values, isInitialLoading])

    // Helper to fetch the final data for the "Done" column
    function getDoneItemById(values, id) {
        return (
            values.final_review.find((item) => item.id === id)?.report ||
            values.done.find((item) => item.id === id)?.report ||
            null
        )
    }

    // Update case (placeholder if needed)
    const updateCaseInfoMap = async (case_id, type, value) => {
        await updateCaseInfo(case_id, type, value, dispatch, refreshAccessTokenCounter)
    }

    // For opening dialogs
    const openReportDialogById = (id) => {
        setDisplayedReportId(id)
        setCaseReportDialogOpen(true)
    }
    const openFilesModalById = (id) => {
        setSelectedFilesCaseId(id)
        setFilesModalOpen(true)
    }
    const closeFilesModal = () => {
        setFilesModalOpen(false)
        setSelectedFilesCaseId(null)
    }

    const getWelcomeSubText = () => {
        return (
            <>
                You currently don’t have any report requests.{" "}
                <a
                    href="https://byvalue.retool.com/apps/fc82aadc-6061-11ef-a0cf-a38acd2495c6/Backoffice/EDD%20Expert%20Space"
                    target="_blank"
                    rel="noreferrer"
                >
                    Assign yourself a new EDD request
                </a>
            </>
        )
    }

    // Find the case whose files are open
    const selectedCase =
        values?.data_collection?.find((item) => item.id === selectedFilesCaseId) ||
        values?.resolutions?.find((item) => item.id === selectedFilesCaseId) ||
        null
    const selectedCaseFiles = selectedCase?.files || []

    return (
        <Box className={styles.mainPageContainer}>
            {/* Fullscreen popup with PDF viewer if "Final Review" */}
            {caseReportDialogOpen && (
                <CaseReportDialog
                    open={caseReportDialogOpen}
                    setClosed={() => setCaseReportDialogOpen(false)}
                    caseTitle={values.title}
                    auditTrailPreview={auditTrailPreview}
                    reportData={getDoneItemById(values, displayedReportId)}
                    displayedReportId={displayedReportId}
                    expert
                    handleFinalizeReportStatus={handleFinalizeReportStatusWrapper}
                />
            )}

            {/* Modal for attached files */}
            <FilesAttachments
                open={filesModalOpen}
                onClose={closeFilesModal}
                caseId={selectedFilesCaseId}
                files={selectedCaseFiles}
            />

            <NewAlert
                openNewAlertPopup={openNewAlertPopup}
                setOpenNewAlertPopup={setOpenNewAlertPopup}
                triggerFetchMainPageData={triggerFetchMainPageData}
                setTriggerFetchMainPageData={setTriggerFetchMainPageData}
                updateCaseInfoMap={updateCaseInfoMap}
                loadMainPageData={loadMainPageData}
            />

            <Sidebar />

            {/* Header */}
            <Box className={styles.mainPageHeaderWrapper}>
                <Typography fontSize="24px" color="#000">
                    My Due Diligence Reports
                </Typography>
            </Box>

            {/* 
               ---- SNACKBAR (Toast) placed next so that it's visually 
               "just above the Kanban board." ---- 
            */}
            <Snackbar
                open={toast.open}
                autoHideDuration={4000}
                onClose={handleCloseToast}
                // Put it near the top, in the center:
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                    "& .MuiSnackbar-root": {
                        marginTop: "100px", // was "60px" before
                    },
                }}
            >
                <Alert
                    onClose={handleCloseToast}
                    severity={toast.severity}
                    sx={{
                        width: "100%",
                        fontSize: "1.2rem", // bigger text
                        py: 2, // extra vertical padding
                    }}
                >
                    {toast.message}
                </Alert>
            </Snackbar>

            {/* Main content: either a loading spinner, a welcome message, or the Kanban */}
            {isInitialLoading ? (
                <Box className={styles.mainPageLoader}>
                    <CircularProgress />
                </Box>
            ) : welcomeState ? (
                <EmptyBox mainText={"Welcome to Redstrings!"} subText={getWelcomeSubText()} />
            ) : (
                <KanbanBoard
                    sections={sections}
                    openReportDialogById={openReportDialogById}
                    openFilesModalById={openFilesModalById}
                    onUploadCSV={handleUploadCSV}
                    onToast={showToast}
                />
            )}
        </Box>
    )
}

export default ComplianceExpertMainPage
