import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const getMainPageData = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_MAIN_PAGE_DATA, {})
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getComplianceMainPageData = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_COMPLIANCE_MAIN_PAGE_DATA, {})
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getAllReportsComplianceExpert = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_ALL_REPORTS_COMPLIANCE_EXPERT)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

/** Renamed from "approveAndSignReport" to "finalizeReportStatus"
 *  Accepts a boolean `isApproved` to decide if we're approving or revising.
 */
export const finalizeReportStatus = async (
    report_request_id,
    isApproved, // true -> Approve & Sign, false -> Revise
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        // The backend can check `isApproved` for further logic
        const { data } = await axios.post(ENUMS.API_ROUTES.FINALIZE_REPORT_STATUS, {
            report_request_id,
            isApproved,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

// NEW: Upload CSV data for a given card (report_request_id)
export const uploadCSVReport = async (
    report_request_id,
    reportDataArray,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const payload = {
            report_request_id,
            report_data: reportDataArray,
        }
        const { data } = await axios.post(ENUMS.API_ROUTES.UPLOAD_CSV_REPORT, payload)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createCase = async (toolbox, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_CASE, { toolbox })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createEDDRequest = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_EDD_REQUEST, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateEDDRequest = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.UPDATE_EDD_REQUEST, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const deleteCase = async (case_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.DELETE_CASE, {
            case_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const runTemplate = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.RUN_TEMPLATE, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const lastXDaysCaseStatuses = async (last_x_days, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.LAST_X_DAYS_CASE_STATUSES, {
            last_x_days,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
