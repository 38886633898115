import { SET_USER_STATE, SET_ADMIN_USERS } from "../actions/types"

const initialState = {
    user: {
        id: null,
        isAdmin: false,
        type: 2,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_STATE:
            return {
                ...state,
                user: action.user,
            }
        case SET_ADMIN_USERS:
            return {
                ...state,
                users: action.users,
            }
        default:
            return state
    }
}
