import React, { useState, useEffect } from "react"
import { Box, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material"
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"
import FFA_LOGO_SMALL from "../../../../../../assets/icons/Repo/FFA-small.svg"
import FFA_LOGO_BIG from "../../../../../../assets/icons/Repo/FFA-big.svg"
import { Skeleton } from "./components/Skeleton"
import { useIsMobile } from "../../../../../../hooks/standard/useIsMobile"
import styles from "./LoadingDialog.module.scss"

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#262626",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 30,
        backgroundColor: "linear-gradient(180deg, #ECEDF2 0%, #DFE0EA 100%))",
    },
}))

function BorderLinearProgressWithLabel({ value, ...props }) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={value} {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    value,
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

export function LoadingDialog({ isLoading }) {
    const isMobile = useIsMobile()

    const [currentTextIndex, setCurrentTextIndex] = useState(0)
    const [progress, setProgress] = useState(0)

    const messages = [
        "Rounding up the usual suspects...",
        "Dusting off the best practices playbook...",
        "Whispering to the walls of the Compliance Council...",
        "Risk patterns spotted! Now connecting the dots...",
        "Having a tea break with fraud experts...",
        "One sec! Our risk radar's beeping...",
    ]

    useEffect(() => {
        const progressValues = [10, 20, 30, 50, 70, 90]
        const intervals = progressValues.map((value, index) =>
            setTimeout(() => setProgress(value), index * 10000),
        )

        const textChangeInterval = setInterval(() => {
            setCurrentTextIndex((prev) => (prev + 1) % messages.length)
        }, 10000)

        return () => {
            intervals.forEach(clearTimeout)
            clearInterval(textChangeInterval)
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            setProgress(100)
        }
    }, [isLoading])

    return (
        <Dialog
            open={true}
            className={styles.loadingDialog}
            PaperProps={{ className: styles.loadingDialogContaier }}
        >
            <DialogTitle className={styles.loadingDialogTitle}>
                <Box className={styles.loadingDialogTitleLogo}>
                    <img src={FFA_LOGO_SMALL} />
                    Fraud Fighters Alliance
                </Box>
            </DialogTitle>
            <DialogContent className={styles.loadingDialogContent}>
                {!isMobile && (
                    <Box className={styles.loadingDialogContentInfo}>
                        <Skeleton width="10%" height="50px" />
                        <Skeleton width="30%" height="30px" />
                        <Skeleton width="80%" height="100px" />
                        <Skeleton width="25%" height="30px" />
                    </Box>
                )}
                <Box className={styles.loadingDialogContentDescription}>
                    <img className={styles.loadingDialogContentDescriptionImg} src={FFA_LOGO_BIG} />
                    <Box className={styles.loadingDialogContentDescriptionText}>
                        <Typography className={styles.loadingDialogContentDescriptionTitle}>
                            FFA are making magic right now
                        </Typography>
                        <Typography className={styles.loadingDialogContentDescriptionSubtitle}>
                            {messages[currentTextIndex]}
                        </Typography>
                    </Box>
                    <Box className={styles.loadingDialogContentDescriptionProgress}>
                        <BorderLinearProgressWithLabel variant="determinate" value={progress} />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
