import React from "react"
import { useRoutes } from "react-router-dom"
import { useSelector } from "react-redux"
import PrivacyComponent from "../components/legal/PrivacyComponent"
import TermsComponent from "../components/legal/TermsComponent"
import RulesOfConduct from "../components/legal/RulesOfConduct"
import ConfigPage from "../pages/ConfigPage/ConfigPage"
import MainPageContainer from "../pages/MainPage/MainPageContainer"
import MainPage from "../pages/MainPage/MainPage"
import ComplianceMainPageContainer from "../pages/ComplianceMainPage/ComplianceMainPageContainer"
import ComplianceMainPage from "../pages/ComplianceMainPage/ComplianceMainPage"
import ComplianceExpertMainPageContainer from "../pages/ComplianceMainPage/ComplianceExpertMainPageContainer"
import ComplianceExpertMainPage from "../pages/ComplianceMainPage/ComplianceExpertMainPage"
import NotebookContainer from "../pages/Notebook/NotebookContainer"
import Integrations from "../pages/Integrations/Integrations"
import { AdminPanel } from "../pages/AdminPanel/AdminPanel"
import { NotFoundPage } from "../pages/NotFoundPage/NotFoundPage"
import { Callback, Redirect, Unauthorized } from "../components/auth0"
import ENUMS from "../constants/appEnums"
import { CaseOverview } from "../pages/Notebook/components/CaseOverview/CaseOverview"
import { Step } from "../pages/Notebook/components/Step/Step"
import Repo from "../pages/Playbook/pages/Repo/Repo"
import MyPlaybooks from "../pages/Playbook/pages/Repo/components/MyPlaybooks/MyPlaybooks"
import { Dashboard } from "../pages/Dashboard/Dashboard"
import { Toolbox } from "../pages/Toolbox/Toolbox"
import { ToolboxRedirect } from "../pages/Toolbox/ToolboxRedirect"

const CloseTab = () => {
    const onClose = () => {
        window.opener = null
        window.open("", "_self")
        window.close()
    }
    React.useEffect(() => {
        onClose()
    }, [])
    return <div></div>
}

export const RouteTable = () => {
    // Get the user type value from Redux store
    const user_type = useSelector((state) => state.userState.user.type)

    // Memoize routes to recompute when user type changes
    const routes = React.useMemo(() => {
        if (user_type === undefined || user_type === null) {
            // While user type is loading, return an empty array
            return []
        }

        const commonRoutes = [
            { path: ENUMS.ROUTES.TERMS, element: <TermsComponent /> },
            { path: ENUMS.ROUTES.PRIVACY, element: <PrivacyComponent /> },
            { path: ENUMS.ROUTES.ROC, element: <RulesOfConduct /> },
            { path: ENUMS.ROUTES.REDIRECT, element: <Redirect /> },
            { path: ENUMS.ROUTES.UNAUTHORIZED, element: <Unauthorized /> },
            { path: ENUMS.ROUTES.OAUTH_CALLBACK, element: <Callback /> },
            { path: "/404", element: <NotFoundPage /> },
            {
                path: ENUMS.ROUTES.CASE_ID,
                element: <NotebookContainer />,
                children: [
                    { index: true, element: <CaseOverview /> },
                    { path: ENUMS.ROUTES.STEP_ID, element: <Step /> },
                    { path: ENUMS.ROUTES.TOOLBOX, element: <Toolbox /> },
                ],
            },
            { path: ENUMS.ROUTES.EASY_INTEGRATIONS, element: <CloseTab /> },
            { path: ENUMS.ROUTES.PLAYBOOK_ID, element: <Repo /> },
            { path: ENUMS.ROUTES.PLAYBOOK, element: <Repo /> },
            { path: ENUMS.ROUTES.MY_PLAYBOOKS, element: <MyPlaybooks /> },
            { path: ENUMS.ROUTES.MY_PLAYBOOKS_ID, element: <MyPlaybooks /> },
            { path: "*", element: <NotFoundPage /> },
        ]

        let userSpecificRoutes = []

        if (user_type === 2 || user_type === 4) {
            // Compliance user
            userSpecificRoutes = [
                {
                    path: "/",
                    element: <ComplianceMainPageContainer />,
                    children: [{ index: true, element: <ComplianceMainPage /> }],
                },
            ]
        } else if (user_type === 3) {
            // Compliance Expert user
            userSpecificRoutes = [
                {
                    path: "/",
                    element: <ComplianceExpertMainPageContainer />,
                    children: [{ index: true, element: <ComplianceExpertMainPage /> }],
                },
            ]
        } else {
            // Regular user
            userSpecificRoutes = [
                {
                    path: "/",
                    element: <MainPageContainer />,
                    children: [
                        { index: true, element: <MainPage /> },
                        { path: ENUMS.ROUTES.DASHBOARD, element: <Dashboard /> },
                        { path: ENUMS.ROUTES.ALL_INTEGRATIONS, element: <Integrations /> },
                        { path: ENUMS.ROUTES.INTERNAL_CONFIGS, element: <ConfigPage /> },
                        { path: ENUMS.ROUTES.ADMIN_PANEL, element: <AdminPanel /> },
                        { path: ENUMS.ROUTES.MAIN_TOOLBOX, element: <ToolboxRedirect /> },
                    ],
                },
            ]
        }

        return [...userSpecificRoutes, ...commonRoutes]
    }, [user_type])

    // Generate route elements using useRoutes
    const element = useRoutes(routes)
    return element
}
