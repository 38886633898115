import React, { useEffect, useState, useRef } from "react"
import { Box, TextField } from "@mui/material"
import stepIcon from "../../../../../../../../../../assets/icons/Repo/step-icon.svg"
import stepIconActive from "../../../../../../../../../../assets/icons/Repo/step-icon-active.svg"
import draggableIcon from "../../../../../../../../../../assets/icons/Repo/draggableIcon.svg"
import { useIsMobile } from "../../../../../../../../../../hooks/standard/useIsMobile"
import { Controller } from "react-hook-form"
import styles from "./Step.module.scss"

export function Step({
    step,
    index,
    handleSelectStep,
    selectedStepIndex,
    control,
    handleEditMode,
    editMode,
    isAllowToEdit,
    templateDataLoading,
    handleDeleteStep,
    editingStepId,
}) {
    const isMobile = useIsMobile()
    const [editName, setEditName] = useState(false)

    const stepnNameInputRef = useRef(null)

    const isActive = selectedStepIndex === index

    const containerClass = isMobile ? styles.stepContainerMobile : styles.stepContainer
    const nameClass = isMobile ? styles.stepNameMobile : styles.stepName

    const handleEditStepName = () => {
        setEditName(true)
        handleEditMode(true)
    }

    useEffect(() => {
        if (step.uniqId === editingStepId) {
            setEditName(true)
            stepnNameInputRef.current?.focus()
        }
    }, [editingStepId, step.uniqId])

    useEffect(() => {
        if (editName) {
            stepnNameInputRef.current?.focus()
        }
    }, [editName])

    useEffect(() => {
        if (!editMode) {
            setEditName(false)
        }
    }, [editMode])

    return (
        <Box
            className={isActive && !isMobile ? styles.stepContainerActive : containerClass}
            onClick={() => handleSelectStep(index)}
        >
            <Box className={nameClass}>
                {isAllowToEdit && (
                    <img src={draggableIcon} alt="draggableIcon" className={styles.draggableIcon} />
                )}
                <img src={isActive ? stepIconActive : stepIcon} />
                <Controller
                    name={`tools.ordered[${index}].log.note`}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <>
                            {!editName ? (
                                <>
                                    <span className={styles.stepNameText}>
                                        {index + 1}. {step?.log?.note}
                                        {isAllowToEdit && !templateDataLoading && (
                                            <div className={styles.stepNameTextIconsWrapper}>
                                                <div
                                                    className={styles.nameEditIcon}
                                                    onClick={handleEditStepName}
                                                ></div>
                                                <div
                                                    className={styles.nameDeleteIcon}
                                                    onClick={() => handleDeleteStep(index)}
                                                ></div>
                                            </div>
                                        )}
                                    </span>
                                </>
                            ) : (
                                <TextField
                                    {...field}
                                    inputRef={stepnNameInputRef}
                                    disabled={templateDataLoading}
                                    onChange={(value) => {
                                        field.onChange(value)
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: "#6B869A",
                                        },
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        className: styles.stepInput,
                                    }}
                                    fullWidth
                                />
                            )}
                        </>
                    )}
                />
            </Box>
        </Box>
    )
}
