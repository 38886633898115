/** KanbanBoard.js **/
import React, { useState, useRef } from "react"
import { Box, Paper, Typography, Button, Tooltip, List, ListItem } from "@mui/material"

// A robust CSV parser to handle quoted fields and commas within quotes:
function parseCSV(csvText) {
    const rows = []
    let currentRow = []
    let currentValue = ""
    let insideQuotes = false

    for (let i = 0; i < csvText.length; i++) {
        const char = csvText[i]
        const nextChar = csvText[i + 1]

        if (char === '"') {
            if (insideQuotes && nextChar === '"') {
                // If we see a double quote inside a quoted field, that's an escaped quote.
                currentValue += '"'
                i++ // Skip the next quote
            } else {
                insideQuotes = !insideQuotes
            }
        } else if (char === "," && !insideQuotes) {
            // Comma not within quotes => end of a column
            currentRow.push(currentValue)
            currentValue = ""
        } else if ((char === "\r" || char === "\n") && !insideQuotes) {
            // End of line
            if (currentValue !== "" || currentRow.length > 0) {
                currentRow.push(currentValue)
                rows.push(currentRow)
            }
            currentValue = ""
            currentRow = []
            // If there's a \r\n combo, skip the next char so we don't get an empty row
            if (char === "\r" && nextChar === "\n") {
                i++
            }
        } else {
            // Normal character
            currentValue += char
        }
    }

    // If there's any leftover in the buffer, push it as the last row
    if (currentValue !== "" || currentRow.length > 0) {
        currentRow.push(currentValue)
        rows.push(currentRow)
    }

    // Convert array-of-arrays into array-of-objects
    if (rows.length < 2) {
        // Means there's either no data or just one line with no header/data
        return []
    }

    const headers = rows[0]
    const dataRows = rows.slice(1)

    // Build objects
    const data = dataRows
        .filter((r) => r.some((cell) => cell.trim() !== "")) // remove entirely empty lines
        .map((row) => {
            const obj = {}
            headers.forEach((header, idx) => {
                const rawVal = row[idx] !== undefined ? row[idx] : ""
                // Remove any leading/trailing quotes (if any remain)
                obj[header.trim()] = rawVal
            })
            return obj
        })

    return data
}

const CaseCard = ({
    row,
    controllerName,
    openReportDialogById,
    openFilesModalById,
    onUploadCSV,
    onToast, // unify all alerts/toasts
}) => {
    const isDataCollection = controllerName === "data_collection"
    const isResolutions = controllerName === "resolutions"
    const isFinalReview = controllerName === "final_review"
    const isDone = controllerName === "done"

    // For uploading CSV
    const fileInputRef = useRef(null)

    // Build button labels based on column
    let downloadButtonLabel = ""
    let uploadButtonLabel = ""

    if (isDataCollection) {
        downloadButtonLabel = "Download Data Collection CSV"
        uploadButtonLabel = "Upload Revised Data Collection CSV"
    } else if (isResolutions) {
        downloadButtonLabel = "Download Resolutions CSV"
        uploadButtonLabel = "Upload Revised Resolutions CSV"
    }

    const handleDownloadCSV = () => {
        if (!row.report) {
            onToast("error", "No report data available for CSV.")
            return
        }
        try {
            let reportData = typeof row.report === "string" ? JSON.parse(row.report) : row.report
            if (!Array.isArray(reportData)) {
                onToast("error", "Expected report data to be an array of objects.")
                return
            }
            const columns = [
                "Section",
                "Check",
                "Data Point",
                "Result",
                "Data Source Description",
                "Risk Level",
                "Red Flags",
                "Notes",
                "Data Source",
            ]
            let csv = columns.join(",") + "\n"
            for (let item of reportData) {
                let rowValues = columns.map((col) => {
                    let val = item[col] ?? ""
                    if (typeof val === "object" && val !== null) {
                        val = JSON.stringify(val)
                    }
                    // Escape quotes by doubling them
                    val = String(val).replace(/"/g, '""')
                    return `"${val}"`
                })
                csv += rowValues.join(",") + "\n"
            }
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            // <entity name>_<id>.csv
            link.setAttribute("download", `${row.entity_name}_${row.id}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        } catch (err) {
            console.error("CSV generation error:", err)
            onToast("error", "Failed to generate CSV. Check console for details.")
        }
    }

    const clientRequestJson = row.client_request
        ? JSON.stringify(row.client_request, null, 2)
        : "{}"

    const handleIdClick = () => {
        if (isFinalReview && openReportDialogById) {
            openReportDialogById(row.id)
        }
    }

    const handleNameClick = () => {
        if (isFinalReview && openReportDialogById) {
            openReportDialogById(row.id)
        }
    }

    const handleFilesClick = () => {
        if ((isDataCollection || isResolutions) && openFilesModalById) {
            openFilesModalById(row.id)
        }
    }

    const handleCSVFileChange = async (e) => {
        const file = e.target.files?.[0]
        if (!file) return
        try {
            const csvText = await file.text()
            // Use the robust parser here:
            const data = parseCSV(csvText)

            if (onUploadCSV) {
                await onUploadCSV(row.id, data)
            }
        } catch (err) {
            console.error("Error parsing CSV:", err)
            onToast("error", "Failed to parse CSV. Check console for details.")
        } finally {
            e.target.value = "" // reset file input
        }
    }

    const triggerUploadCSV = () => {
        fileInputRef.current?.click()
    }

    return (
        <Paper
            elevation={2}
            sx={{
                mb: 2,
                p: 2,
                borderRadius: "8px",
                backgroundColor: "#fff",
            }}
        >
            <Box mb={1} display="flex" justifyContent="space-between">
                {/* ID, TYPE */}
                <Typography fontSize="14px" color="text.primary" noWrap>
                    ID:&nbsp;
                    {isFinalReview ? (
                        <span
                            style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={handleIdClick}
                        >
                            {row.id}
                        </span>
                    ) : (
                        row.id
                    )}
                </Typography>
                <Typography fontSize="14px" color="text.primary" noWrap>
                    {row.entity_type}
                </Typography>
            </Box>

            {/* NAME */}
            <Typography mb={1} fontSize="15px" fontWeight="500" color="text.primary" noWrap>
                {isFinalReview ? (
                    <span
                        style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                        onClick={handleNameClick}
                    >
                        {row.entity_name}
                    </span>
                ) : (
                    row.entity_name
                )}
            </Typography>

            {/* CSV buttons: Only in Data Collection & Resolutions */}
            {(isDataCollection || isResolutions) && (
                <>
                    <Button
                        variant="contained"
                        disableRipple
                        sx={{
                            textTransform: "none",
                            mb: 1,
                            color: "#fff",
                            backgroundColor: "#5E4AFD",
                            "&:hover": {
                                backgroundColor: "#5E4AFD", // same color on hover
                            },
                        }}
                        onClick={handleDownloadCSV}
                        size="small"
                    >
                        {downloadButtonLabel}
                    </Button>

                    <input
                        type="file"
                        accept=".csv"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleCSVFileChange}
                    />

                    <Button
                        variant="contained"
                        disableRipple
                        sx={{
                            textTransform: "none",
                            mb: 1,
                            ml: 1,
                            color: "#fff",
                            backgroundColor: "#5E4AFD",
                            "&:hover": {
                                backgroundColor: "#5E4AFD",
                            },
                        }}
                        onClick={triggerUploadCSV}
                        size="small"
                    >
                        {uploadButtonLabel}
                    </Button>
                </>
            )}

            {/* CLIENT REQUEST => data_collection, resolutions, final_review */}
            {(isDataCollection || isResolutions || isFinalReview) && (
                <Tooltip
                    title={
                        <pre style={{ whiteSpace: "pre-wrap", maxWidth: "300px" }}>
                            {clientRequestJson}
                        </pre>
                    }
                    arrow
                >
                    <Typography
                        fontSize="14px"
                        color="blue"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        noWrap
                    >
                        View Client Request
                    </Typography>
                </Tooltip>
            )}

            {/* FILES => data_collection & resolutions */}
            {(isDataCollection || isResolutions) && (
                <Typography
                    mt={1}
                    fontSize="14px"
                    color="blue"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    noWrap
                    onClick={handleFilesClick}
                >
                    View Files
                </Typography>
            )}
        </Paper>
    )
}

const KanbanColumn = ({
    headerTitle,
    controllerName,
    cases,
    emptyText,
    openReportDialogById,
    openFilesModalById,
    onUploadCSV,
    onToast,
}) => {
    return (
        <Box
            // Each column has a grey background, same width, fill height
            sx={{
                flex: 1,
                bgcolor: "#eee",
                borderRadius: "8px",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
            }}
        >
            <Typography variant="h6" mb={1}>
                {headerTitle}
            </Typography>
            {!cases || cases.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                    {emptyText}
                </Typography>
            ) : (
                cases.map((caseItem) => (
                    <CaseCard
                        key={caseItem.id}
                        row={caseItem}
                        controllerName={controllerName}
                        openReportDialogById={openReportDialogById}
                        openFilesModalById={openFilesModalById}
                        onUploadCSV={onUploadCSV}
                        onToast={onToast}
                    />
                ))
            )}
        </Box>
    )
}

const KanbanBoard = ({
    sections,
    openReportDialogById,
    openFilesModalById,
    onUploadCSV,
    onToast,
}) => {
    // Hide "done" column by default
    const [showDone, setShowDone] = useState(false)

    // Separate out the done column
    const doneSection = sections.find((s) => s.controllerName === "done")
    const otherSections = sections.filter((s) => s.controllerName !== "done")

    return (
        <Box
            // Full screen (minus topbars/etc.) + margin
            sx={{
                width: "100%",
                height: "calc(100vh - 150px)",
                display: "flex",
                flexDirection: "column",
                margin: "20px",
            }}
        >
            {/* Toggle button for the "done" column */}
            <Box mb={2}>
                <Button
                    variant="outlined"
                    onClick={() => setShowDone(!showDone)}
                    sx={{ textTransform: "none" }}
                >
                    {showDone ? "Hide Done Column" : "Show Done Column"}
                </Button>
            </Box>

            {/* Main row for columns */}
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    gap: "20px",
                }}
            >
                {otherSections.map((section, index) => (
                    <KanbanColumn
                        key={section.controllerName + index}
                        headerTitle={section.headerTitle}
                        controllerName={section.controllerName}
                        cases={section.cases}
                        emptyText={section.emptyText}
                        openReportDialogById={openReportDialogById}
                        openFilesModalById={openFilesModalById}
                        onUploadCSV={onUploadCSV}
                        onToast={onToast}
                    />
                ))}

                {/* "Done" column as a list view, shown only if user toggles on */}
                {showDone && doneSection && (
                    <Box
                        sx={{
                            flex: 1,
                            bgcolor: "#eee",
                            borderRadius: "8px",
                            padding: "1rem",
                            overflowY: "auto",
                        }}
                    >
                        <Typography variant="h6" mb={1}>
                            {doneSection.headerTitle}
                        </Typography>
                        {doneSection.cases && doneSection.cases.length > 0 ? (
                            <List disablePadding>
                                {doneSection.cases.map((caseItem) => (
                                    <ListItem
                                        key={caseItem.id}
                                        sx={{
                                            mb: 1,
                                            borderRadius: "4px",
                                            backgroundColor: "#fff",
                                        }}
                                    >
                                        {caseItem.entity_name} (ID: {caseItem.id})
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                {doneSection.emptyText}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default KanbanBoard
