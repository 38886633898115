/** ComplianceExpertMainPageContainer.js **/

import React, { useEffect, useState } from "react"
import {
    getAllReportsComplianceExpert,
    finalizeReportStatus, // Renamed in prior step
} from "../../services/main/mainServices"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelector, useDispatch } from "react-redux"
import { applyRedstringsLoader } from "../../redux/actions/loaderActions"
import withCustomAuthenticationRequired from "../../components/Hoc/with-custom-authentication-required"
import { AsideMenu } from "./components/AsideMenu/AsideMenu"
import { Outlet, useLocation } from "react-router-dom"
import Topbar from "../../components/TopBar/Topbar"
import { mainPageInitialValues } from "../../constants/initialValues"
import { complianceMainPageMenuItems } from "../../constants/appConstants"
import { Box } from "@mui/material"

const validationSchema = yup.object().shape({})

function ComplianceExpertMainPageContainer() {
    const dispatch = useDispatch()
    const location = useLocation()

    const [initialMainPageDataLoadDone, setInitialMainPageDataLoadDone] = useState(false)
    const [triggerFetchMainPageData, setTriggerFetchMainPageData] = useState(0)
    const [isInitialLoading, setIsInitialLoading] = useState(false)
    const [isLoadingMainPageData, setIsLoadingMainPageData] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState(0)
    const [openMenu, setOpenMenu] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const loaderState = useSelector((state) => state.loaderState)
    const successState = useSelector((state) => state.successState)

    if (successState)
        setTimeout(() => {
            dispatch(applyRedstringsLoader())
        }, 4400)

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm({
        defaultValues: mainPageInitialValues,
        resolver: yupResolver(validationSchema),
    })

    const values = watch()

    const loadMainPageData = async () => {
        setIsLoadingMainPageData(true)
        try {
            const mainPageData = await getAllReportsComplianceExpert(
                dispatch,
                refreshAccessTokenCounter,
            )
            reset(mainPageData)
        } catch (e) {
            console.log(e.message)
        } finally {
            setIsInitialLoading(false)
            setIsLoadingMainPageData(false)
            setInitialMainPageDataLoadDone(true)
        }
    }

    // The new function that calls finalizeReportStatus
    const handleFinalizeReportStatus = async (report_request_id, isApproved = true) => {
        setIsLoadingMainPageData(true)
        try {
            const mainPageData = await finalizeReportStatus(
                report_request_id,
                isApproved,
                dispatch,
                refreshAccessTokenCounter,
            )
            reset(mainPageData)
        } catch (e) {
            console.log(e.message)
        } finally {
            setIsInitialLoading(false)
            setIsLoadingMainPageData(false)
            setInitialMainPageDataLoadDone(true)
        }
    }

    // set selected menu item
    useEffect(() => {
        const selectedItem = complianceMainPageMenuItems().findIndex(
            (menuItem) => menuItem.to === location.pathname,
        )
        setSelectedMenuItem(selectedItem !== -1 ? selectedItem : 0)
    }, [location.pathname])

    // load initial data
    useEffect(() => {
        if (isAuthorized) {
            setIsInitialLoading(true)
            loadMainPageData()
        }
    }, [isAuthorized, triggerFetchMainPageData])

    // reload case data by interval
    useEffect(() => {
        if (isAuthorized && !isLoadingMainPageData) {
            const interval = setInterval(
                () => {
                    loadMainPageData()
                },
                process.env.REACT_APP_MACHINE === "local" ? 15000 : 3500,
            )
            return () => clearInterval(interval)
        }
    }, [isAuthorized, triggerFetchMainPageData, isLoadingMainPageData])

    return (
        !loaderState && (
            <form onSubmit={handleSubmit(() => {})}>
                <Topbar />
                <AsideMenu
                    setSelectedMenuItem={setSelectedMenuItem}
                    setOpenMenu={setOpenMenu}
                    openMenu={openMenu}
                    selectedMenuItem={selectedMenuItem}
                />
                <Box
                    style={{
                        marginLeft: openMenu ? "195px" : "0px",
                        transition: "all 0.5s ease",
                    }}
                >
                    <Outlet
                        context={[
                            control,
                            values,
                            triggerFetchMainPageData,
                            setTriggerFetchMainPageData,
                            loadMainPageData,
                            isInitialLoading,
                            handleFinalizeReportStatus,
                        ]}
                    />
                </Box>
            </form>
        )
    )
}

export default withCustomAuthenticationRequired(ComplianceExpertMainPageContainer)
