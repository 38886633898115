import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Controller } from "react-hook-form"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { Box, Typography, CircularProgress, TextField, Divider } from "@mui/material"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import { uploadToS3 } from "../../../../hooks/standard/uploadFile"
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
import { uuidv4 } from "../../../../helpers/standardHelpers"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { DotsMenu } from "../DotsMenu"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import styles from "./ShareCode.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
}))

function ShareCode({
    tool_id,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    integrations,
    toolsConfig,
    refreshPageData,
    resetField,
    addedToolId,
    newTool,
    subtitle,
    isFromToolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isFileLoading, setIsFileLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState(values?.tools[tool_id]?.log?.url)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(newTool ? true : false)

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (values) {
                await doToolAction(
                    values.case_id,
                    values.tools[tool_id]?.id,
                    values.tools[tool_id]?.type,
                    values.tools[tool_id]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully save data"))
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${values.tools[tool_id]?.id}.log.note`)
    }

    const handleUploadFile = async (logo_file_singleton_list) => {
        setIsFileLoading(true)
        try {
            var file = logo_file_singleton_list[0]
            const response = await uploadToS3(
                "code",
                file,
                `code_${uuidv4().slice(0, 8)}` + file.name.slice(file.name.indexOf(".")),
                true,
                dispatch,
                refreshAccessTokenCounter,
            )
            const file_address = response.config.url.slice(0, response.config.url.indexOf("?"))

            await doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                values.tools[tool_id]?.type,
                { ...values.tools[tool_id]?.log, url: file_address },
                dispatch,
                refreshAccessTokenCounter,
            )
            setFile(file_address)
            dispatch(handleShowToaster("success", "Successfully upload file"))
        } catch (e) {
            console.log(e)
        } finally {
            setIsFileLoading(false)
        }
    }

    const handleDowloadImage = async () => {
        const response = await fetch(file)
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = file
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id)) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location])

    return (
        <Box>
            <Accordion
                style={{ "border-radius": "10px", cursor: "default" }}
                className={styles.shareCodeAccordion}
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    className={classes.accordionImage}
                    style={{
                        cursor: "default",
                        backgroundColor: "transparent",
                        flexDirection: "row-reverse",
                        gap: "10px",
                    }}
                    expandIcon={
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toggleAccordion}
                            src={accordionExpand}
                        ></img>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={styles.shareCodeTitleWrapper}>
                        {/* <img className={styles.standartApiTitleLogo} src={logo_url}></img> */}
                        <Box display="flex" flexDirection="column" gap="5px">
                            <Typography className={styles.shareCodeTitleText}>
                                Share Code
                            </Typography>
                            <Typography className={styles.shareCodeSubtitle}>{subtitle}</Typography>
                        </Box>
                        {isDeleteLoading || isLoadingNewTool ? (
                            <Box className={styles.shareCodeLoaderWrapper}>
                                <CircularProgress
                                    color="inherit"
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </Box>
                        ) : (
                            <Box className={styles.shareCodeActionsWrapper}>
                                <RiskLevel
                                    values={values}
                                    tool_id={tool_id}
                                    control={control}
                                    resetField={resetField}
                                    refreshPageData={refreshPageData}
                                    toolLog={values.tools[tool_id].log || {}}
                                />

                                {!isFromToolbox && (
                                    <DotsMenu
                                        handleAddTools={handleAddTools}
                                        index_x={index_x}
                                        index_y={index_y}
                                        values={values}
                                        integrations={integrations}
                                        toolsConfig={toolsConfig}
                                        handleDeleteTool={handleDeleteTool}
                                        tool_id={tool_id}
                                        setIsDeleteLoading={setIsDeleteLoading}
                                        setIsLoadingNewTool={setIsLoadingNewTool}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <Divider className={styles.shareCodeDivider} />
                <Box className={styles.shareCodeInputWrapper}>
                    {file ? (
                        <div
                            style={{ display: "flex", marginBottom: "20px", alignItems: "center" }}
                        >
                            <img
                                src="https://cdn3.iconfinder.com/data/icons/brands-applications/512/File-512.png"
                                alt="Shared Code"
                                width="65"
                                height="65"
                            />
                            <Box display="flex" gap="10px" marginLeft="10px">
                                <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setFile(null)}
                                />
                                <DownloadIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDowloadImage()}
                                />
                            </Box>
                        </div>
                    ) : (
                        <>
                            {isFileLoading ? (
                                <CircularProgress
                                    color="inherit"
                                    style={{
                                        marginLeft: "10px",
                                        marginBottom: "10px",
                                        width: "25px",
                                        height: "25px",
                                    }}
                                />
                            ) : (
                                <label className={styles.inputFile}>
                                    <input
                                        id="files"
                                        name="files"
                                        type="file"
                                        onChange={(event) => {
                                            handleUploadFile(event.currentTarget.files)
                                        }}
                                    />
                                    <span>Choose file</span>
                                </label>
                            )}
                        </>
                    )}

                    <Controller
                        name={`tools.${tool_id}.log.note`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                label="File Note"
                                placeholder="File Note"
                                minRows={3}
                                multiline
                                InputProps={{
                                    className: styles.input,
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#6B869A",
                                    },
                                }}
                                fullWidth
                                disabled={isLoading}
                            />
                        )}
                    />
                    <Box className={styles.editIcons}>
                        {isLoading ? (
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        ) : (
                            <>
                                <Box display="flex" gap="15px">
                                    <CloseIcon
                                        onClick={handleCancel}
                                        className={styles.standartIcon}
                                    />
                                    <DoneIcon
                                        onClick={handleSave}
                                        className={styles.standartIcon}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Accordion>
        </Box>
    )
}

export default ShareCode
