import React, { useCallback, useState, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import CaseInformationMainPage from "./CaseInformationMainPage"
import { useSelector, useDispatch } from "react-redux"
import { createEDDRequest, updateEDDRequest } from "../../../services/main/mainServices"
import {
    Button,
    Typography,
    Link,
    Box,
    Paper,
    IconButton,
    LinearProgress,
    Checkbox,
    FormControlLabel,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import { handleShowToaster } from "../../../redux/actions/showToasterActions"
import { uploadToS3 } from "../../../hooks/standard/uploadFile"
import { uuidv4 } from "../../../helpers/standardHelpers"
import { useDropzone } from "react-dropzone"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

const useStyles = makeStyles(() => ({
    input: {
        height: 58,
        maxWidth: "100%",
        borderRadius: "10px",
    },
    label: {
        fontSize: "20px",
        color: "#000",
    },
    dropzone: {
        border: "2px dashed #aaa",
        borderRadius: "10px",
        padding: "40px 20px",
        textAlign: "center",
        cursor: "pointer",
        marginTop: "10px",
        marginBottom: "20px",
        transition: "border .3s ease-in-out",
        "&:hover": {
            borderColor: "#000",
        },
        backgroundColor: "#fafafa",
    },
    dropzoneActive: {
        borderColor: "#000",
        backgroundColor: "#f0f0f0",
    },
    fileCard: {
        marginBottom: "10px",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "column",
    },
    fileInfo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    fileName: {
        wordBreak: "break-all",
        maxWidth: "80%",
    },
    progressContainer: {
        marginTop: "5px",
    },
    successIcon: {
        color: "green",
        marginLeft: "10px",
    },
    errorIcon: {
        color: "red",
        marginLeft: "10px",
    },
}))

const validationSchema = yup.object().shape({
    "Company Name": yup.string().required("Company Name is required"),
    Country: yup.string().required("Country is required"),
    "Registration Number": yup.string().required("Registration Number is required"),
})

const NewAlert = ({
    openNewAlertPopup,
    setOpenNewAlertPopup,
    triggerFetchMainPageData,
    setTriggerFetchMainPageData,
    updateCaseInfoMap,
    loadMainPageData,
    caseTypes,
}) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const navigate = useNavigate()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [eddId, setEddId] = useState(null)
    // This state tracks whether the user checked the box or not.
    const [termsAccepted, setTermsAccepted] = useState(false)

    const user_type = useSelector((state) => state.userState.user.type)

    const initialValues = {
        "Company Name": "",
        Country: "",
        "Registration Number": "",
        "Additional Information": [],
        attachments: [],
    }

    const {
        formState: { errors },
        control,
        watch,
        setValue,
        reset,
        getValues,
        handleSubmit,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        mode: "onSubmit",
    })

    const values = watch()

    useEffect(() => {
        const handleOpen = async () => {
            if (openNewAlertPopup) {
                try {
                    const createdId = await createEDDRequest(
                        {},
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setEddId(createdId)
                } catch (err) {
                    console.error(err)
                    dispatch(handleShowToaster("error", "Failed to create EDD draft"))
                    setOpenNewAlertPopup(false)
                }
            } else {
                setEddId(null)
                reset(initialValues)
                setAttachments([])
                setTermsAccepted(false)
            }
        }
        handleOpen()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openNewAlertPopup])

    const uploadFile = async (file, attachmentId) => {
        try {
            const filenameWithoutExtension = file.name.slice(0, file.name.lastIndexOf("."))
            const uniqueFilename = `_${uuidv4()?.slice(
                0,
                8,
            )}_${filenameWithoutExtension}${file.name.slice(file.name.lastIndexOf("."))}`

            await uploadToS3(
                `1/${eddId}`,
                file,
                uniqueFilename,
                false,
                dispatch,
                refreshAccessTokenCounter,
                (progress) => {
                    setAttachments((prev) =>
                        prev.map((att) => (att.id === attachmentId ? { ...att, progress } : att)),
                    )
                },
            ).then((response) => {
                const fileUrl = response?.config?.url.slice(0, response?.config?.url.indexOf("?"))
                setAttachments((prev) =>
                    prev.map((att) =>
                        att.id === attachmentId
                            ? { ...att, url: fileUrl, uploading: false, progress: 100 }
                            : att,
                    ),
                )
            })
        } catch (error) {
            console.error(error)
            dispatch(handleShowToaster("error", `Failed to upload file: ${file.name}`))
            setAttachments((prev) =>
                prev.map((att) =>
                    att.id === attachmentId ? { ...att, uploading: false, error: true } : att,
                ),
            )
        }
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!eddId) {
                dispatch(
                    handleShowToaster(
                        "error",
                        "EDD not initialized, please wait before uploading files.",
                    ),
                )
                return
            }

            const newAttachments = acceptedFiles.map((file) => ({
                id: uuidv4(),
                name: file.name,
                uploading: true,
                progress: 0,
                error: false,
            }))

            setAttachments((prev) => [...prev, ...newAttachments])
            newAttachments.forEach((att, index) => {
                uploadFile(acceptedFiles[index], att.id)
            })
        },
        [dispatch, refreshAccessTokenCounter, eddId],
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/*": [],
            "application/pdf": [],
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
            "text/plain": [],
        },
        multiple: true,
    })

    const removeAttachment = (attachmentId) => {
        setAttachments((prev) => prev.filter((att) => att.id !== attachmentId))
    }

    const onSubmit = async (currentValues) => {
        try {
            setIsSubmitting(true)
            const submittedAttachments = attachments
                .filter((att) => att.url && !att.error)
                .map((att) => ({ name: att.name, url: att.url }))

            currentValues.attachments = submittedAttachments

            const req = {
                edd_id: eddId,
                client_request: currentValues,
            }

            await updateEDDRequest(req, dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", "EDD created successfully"))

            setTriggerFetchMainPageData(triggerFetchMainPageData + 1)
            loadMainPageData()
            setOpenNewAlertPopup(false)
        } catch (err) {
            console.error(err)
            dispatch(handleShowToaster("error", "Failed to create EDD"))
        } finally {
            reset(initialValues)
            setAttachments([])
            setTermsAccepted(false)
            setIsSubmitting(false)
        }
    }

    const onError = (formErrors) => {
        if (formErrors["Company Name"]) {
            dispatch(handleShowToaster("error", formErrors["Company Name"].message))
        }

        if (formErrors["Country"]) {
            dispatch(handleShowToaster("error", formErrors["Country"].message))
        }
        if (formErrors["Registration Number"]) {
            dispatch(handleShowToaster("error", formErrors["Registration Number"].message))
        }
    }

    const anyUploading = attachments.some((att) => att.uploading === true)

    return (
        <>
            <Dialog
                open={openNewAlertPopup}
                onClose={() => {
                    reset(initialValues)
                    setAttachments([])
                    setTermsAccepted(false)
                    setOpenNewAlertPopup(false)
                }}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"New EDD"}</DialogTitle>
                <DialogContent
                    sx={{
                        maxHeight: "634px",
                        overflowY: "auto",
                    }}
                >
                    <CaseInformationMainPage
                        control={control}
                        values={values}
                        setValue={setValue}
                        resetForm={reset}
                        getValues={getValues}
                        errors={errors}
                    />

                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Attachments
                    </Typography>
                    {eddId ? (
                        <Box
                            {...getRootProps()}
                            className={`${classes.dropzone} ${
                                isDragActive ? classes.dropzoneActive : ""
                            }`}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <Typography>Drop the files here...</Typography>
                            ) : (
                                <Typography>
                                    Drag &amp; drop files here, or click to select files
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Typography>Loading EDD Request ID, please wait...</Typography>
                    )}
                    {attachments.length > 0 && (
                        <Box>
                            {attachments.map((att) => (
                                <Paper key={att.id} className={classes.fileCard}>
                                    <Box className={classes.fileInfo}>
                                        <Box className={classes.fileName}>
                                            {att.url && !att.uploading && !att.error ? (
                                                <a
                                                    href={att.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {att.name}
                                                </a>
                                            ) : (
                                                <Typography>{att.name}</Typography>
                                            )}
                                        </Box>
                                        <Box>
                                            {att.uploading && (
                                                <Typography variant="body2" color="textSecondary">
                                                    Uploading...
                                                </Typography>
                                            )}
                                            {!att.uploading && att.url && !att.error && (
                                                <CheckCircleIcon className={classes.successIcon} />
                                            )}
                                            {!att.uploading && att.error && (
                                                <ErrorOutlineIcon className={classes.errorIcon} />
                                            )}
                                            <IconButton
                                                size="small"
                                                onClick={() => removeAttachment(att.id)}
                                                disabled={att.uploading}
                                                title="Remove file"
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {att.uploading && (
                                        <Box className={classes.progressContainer}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={att.progress}
                                            />
                                        </Box>
                                    )}
                                    {!att.uploading && att.error && (
                                        <Typography variant="body2" color="error">
                                            Failed to upload this file. You can remove it.
                                        </Typography>
                                    )}
                                </Paper>
                            ))}
                        </Box>
                    )}

                    {user_type === 4 && (
                        <Box sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={termsAccepted}
                                        onChange={(e) => setTermsAccepted(e.target.checked)}
                                        name="termsAccepted"
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="textSecondary">
                                        Each EDD request costs $85 and will be invoiced on the next
                                        business day for all reports ordered the previous day. For
                                        more details, please refer to our{" "}
                                        <Link
                                            href="https://redstrings.io/terms-and-conditions/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            sx={{ color: "blue" }} // Ensures the link is blue
                                        >
                                            terms and conditions
                                        </Link>
                                        .
                                    </Typography>
                                }
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "space-between",
                        padding: "20px 24px 20px 24px",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                            width: "200px",
                            height: "42.5px",
                            borderRadius: "10px",
                            borderColor: "#000000",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            setOpenNewAlertPopup(false)
                            reset(initialValues)
                            setAttachments([])
                            setTermsAccepted(false)
                        }}
                        disabled={isSubmitting || anyUploading}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{
                            backgroundColor: "#000",
                            color: "#fff",
                            borderRadius: "38px",
                            "&:hover": {
                                backgroundColor: "#343434",
                            },
                            // Styles for the disabled state
                            "&.Mui-disabled": {
                                backgroundColor: "grey",
                                color: "#fff",
                            },
                            width: "200px",
                            height: "42.5px",
                            textTransform: "none",
                        }}
                        onClick={handleSubmit(onSubmit, onError)}
                        loading={isSubmitting}
                        // Disable if any files are uploading, no EDD ID yet, or if user is type=4 but hasn't checked the box
                        disabled={anyUploading || !eddId || (user_type === 4 && !termsAccepted)}
                    >
                        <Typography
                            color="#fff"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            REQUEST EDD
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewAlert
