import React, { useEffect, useState } from "react"
import { getCaseTypesWithFields } from "../../services/standard/standardServices"
import { getComplianceMainPageData } from "../../services/main/mainServices"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelector, useDispatch } from "react-redux"
import { changeLoaderState, applyRedstringsLoader } from "../../redux/actions/loaderActions"
import withCustomAuthenticationRequired from "../../components/Hoc/with-custom-authentication-required"
import { AsideMenu } from "./components/AsideMenu/AsideMenu"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import Topbar from "../../components/TopBar/Topbar"
import { mainPageInitialValues } from "../../constants/initialValues"
import { complianceMainPageMenuItems } from "../../constants/appConstants"
import { Box } from "@mui/material"

const validationSchema = yup.object().shape({})

function ComplianceMainPageContainer() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [caseTypes, setCaseTypes] = useState([])
    const [loadData, setLoadData] = useState({
        initialMainPage: false,
        caseTypes: false,
    })
    const [initialMainPageDataLoadDone, setInitialMainPageDataLoadDone] = useState(false)
    const [triggerFetchMainPageData, setTriggerFetchMainPageData] = useState(0)
    const [isInitialLoading, setIsInitialLoading] = useState(false)
    const [isLoadingMainPageData, setIsLoadingMainPageData] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState(0)
    const [openMenu, setOpenMenu] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const loaderState = useSelector((state) => state.loaderState)
    const successState = useSelector((state) => state.successState)

    if (successState)
        setTimeout(() => {
            dispatch(applyRedstringsLoader())
        }, 4400)

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        reset,
    } = useForm({
        defaultValues: mainPageInitialValues,
        resolver: yupResolver(validationSchema),
    })

    const values = watch()

    // initiate loading data every x second
    const loadMainPageData = async () => {
        setIsLoadingMainPageData(true)
        try {
            const mainPageData = await getComplianceMainPageData(
                dispatch,
                refreshAccessTokenCounter,
            )
            reset(mainPageData)
        } catch (e) {
            console.log(e.message)
        } finally {
            setIsInitialLoading(false)
            setIsLoadingMainPageData(false)
            setInitialMainPageDataLoadDone(true)
        }
    }

    useEffect(() => {
        if (Object.values(loadData).every((element) => element)) {
            dispatch(changeLoaderState(false))
        }
    }, [loadData])

    // set selected menu item
    useEffect(() => {
        const selectedItem = complianceMainPageMenuItems().findIndex(
            (menuItem) => menuItem.to === location.pathname,
        )
        setSelectedMenuItem(selectedItem !== -1 ? selectedItem : 0)
    }, [location.pathname])

    // load initial data
    useEffect(() => {
        setLoadData((loadData) => ({ ...loadData, initialMainPage: true }))
    }, [initialMainPageDataLoadDone])

    // load initial data
    useEffect(() => {
        if (isAuthorized) {
            setIsInitialLoading(true)
            loadMainPageData()
        }
    }, [isAuthorized, triggerFetchMainPageData])

    // reload case data by interval
    useEffect(() => {
        if (isAuthorized && !isLoadingMainPageData) {
            const interval = setInterval(
                () => {
                    loadMainPageData()
                },
                process.env.REACT_APP_MACHINE === "local" ? 15000 : 3500,
            )
            return () => clearInterval(interval)
        }
    }, [isAuthorized, triggerFetchMainPageData, isLoadingMainPageData])

    // case types
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const caseTypesResults = await getCaseTypesWithFields(
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setCaseTypes(caseTypesResults)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    setLoadData((loadData) => ({
                        ...loadData,
                        caseTypes: true,
                    }))
                }
            })()
        }
    }, [isAuthorized])

    return (
        !loaderState && (
            <form onSubmit={handleSubmit(() => {})}>
                <Topbar />
                <AsideMenu
                    setSelectedMenuItem={setSelectedMenuItem}
                    setOpenMenu={setOpenMenu}
                    openMenu={openMenu}
                    selectedMenuItem={selectedMenuItem}
                />
                <Box
                    style={{
                        marginLeft: openMenu ? "195px" : "0px",
                        transition: "all 0.5s ease",
                    }}
                >
                    <Outlet
                        context={[
                            control,
                            values,
                            triggerFetchMainPageData,
                            setTriggerFetchMainPageData,
                            loadMainPageData,
                            caseTypes,
                            isInitialLoading,
                        ]}
                    />
                </Box>
            </form>
        )
    )
}

export default withCustomAuthenticationRequired(ComplianceMainPageContainer)
