import React from "react"
import { Box } from "@mui/material"
import Slider from "react-slick"
import makeStyles from "@mui/styles/makeStyles"
import { StepsCarouselCard } from "../StepsCarouselCard/StepsCarouselCard"
import { useIsSmallScreen } from "../../../../../../hooks/standard/useIsSmallScreen"

const useStyles = makeStyles(() => ({
    arrows: {
        "& .slick-next": {
            right: -20,
            "&:before": {
                color: "#6B869A",
            },
        },
        "& .slick-prev": {
            left: -20,
            "&:before": {
                color: "#6B869A",
            },
        },
    },
}))

export function StepsCarousel({ steps, openMenu, selectedMenuItem, handleAddTools }) {
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreen()
    const sliderLength = openMenu || selectedMenuItem != null ? 4 : 5

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: sliderLength,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: 0,
        draggable: false,
    }

    const stepsLength = steps?.length || 0

    const emptySlides = Array.from({ length: sliderLength - stepsLength }, (_, index_x) => (
        <div key={"empty-" + index_x} style={{ visibility: "hidden" }}>
            {/* Empty slide to avoid center all slides less sliderLength */}
        </div>
    ))

    return (
        <Box>
            <Slider {...settings} className={classes.arrows} variableWidth={true}>
                {steps.map((step, index_x) => (
                    <StepsCarouselCard
                        log={step?.log}
                        key={step?.id || index_x}
                        index_x={step?.index_x}
                        id={step?.id}
                        handleAddTools={handleAddTools}
                        openMenu={openMenu}
                    />
                ))}
                {emptySlides}
            </Slider>
        </Box>
    )
}
