import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import { useAuth0 } from "@auth0/auth0-react"
import { Box, Typography, IconButton, Menu, MenuItem, Button } from "@mui/material"
import FFA_LOGO_SMALL from "../../../../../../assets/icons/Repo/FFA-small.svg"
import REDSTRINGS_LOGO from "../../../../../../assets/icons/Repo/redstings-logo.svg"
import { LoginLinkContainerAuth0 } from "../../../../../../pages/Login/Auth0Login"
import { useIsMobile } from "../../../../../../hooks/standard/useIsMobile"
import { isActiveRoute } from "../../../../../../helpers/playbookHelpers"
import styles from "./RepoHeader.module.scss"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import LogoutIcon from "@mui/icons-material/Logout"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ShareIcon from "@mui/icons-material/Share"
import ShareDialog from "./ShareDialog"

const drawerWidth = 240

const PATH_PLAYBOOK = "/home"
const PATH_MY_PLAYBOOKS = "/my-playbooks"
const PATH_MY_TOOLBOX = "/toolbox"

const activeColor = "#2679DC"

export function RepoHeader({ toolbox = false }) {
    const { logout, user } = useAuth0()
    const isMobile = useIsMobile()
    const location = useLocation()
    const [sidebarState, setSidebarState] = useState(false)
    const [sendToolboxDialogOpen, setSendToolboxDialogOpen] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleLogOut = () => {
        handleMenuClose()
        logout({ returnTo: window.location.origin })
    }

    const repoHeaderTitle = (
        <p className={styles.repoHeaderTitle}>
            <Link to={PATH_PLAYBOOK} style={{ display: "flex", alignItems: "center" }}>
                <img
                    src={FFA_LOGO_SMALL}
                    alt="FFA_LOGO_SMALL"
                    className={styles.repoHeaderTitleFfaImg}
                />
            </Link>
            <Link to={PATH_PLAYBOOK} className={styles.repoHeaderTitleFfaText}>
                FRAUD FIGHTERS ALLIANCE
            </Link>
            {!isMobile && (
                <Box className={styles.repoHeaderTitleRedstrings}>
                    <span className={styles.repoHeaderTitleRedstringsText}>Powered By</span>
                    <img
                        className={styles.repoHeaderTitleRedstringsImg}
                        src={REDSTRINGS_LOGO}
                        alt="REDSTRINGS_LOGO_SMALL"
                    />
                </Box>
            )}
        </p>
    )

    const renderUserMenu = () => {
        if (user) {
            return (
                <>
                    <ShareDialog
                        sendToolboxDialogOpen={sendToolboxDialogOpen}
                        setSendToolboxDialogOpen={setSendToolboxDialogOpen}
                    />
                    {isMobile ? (
                        <IconButton onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <>
                            <Box className={styles.repoHeaderUserInfoMiddle}>
                                <Link to={PATH_MY_TOOLBOX} className={styles.repoHeaderUserLink}>
                                    <Typography
                                        fontSize={14}
                                        fontWeight={600}
                                        color={isActiveRoute(PATH_MY_TOOLBOX) && activeColor}
                                    >
                                        Toolbox
                                    </Typography>
                                </Link>

                                <Link to={PATH_MY_PLAYBOOKS} className={styles.repoHeaderUserLink}>
                                    <Typography
                                        fontSize={14}
                                        fontWeight={600}
                                        color={isActiveRoute(PATH_MY_PLAYBOOKS) && activeColor}
                                    >
                                        My Repository
                                    </Typography>
                                </Link>
                            </Box>
                            <div></div>
                            <Box className={styles.repoHeaderUserInfoWrapper}>
                                {toolbox && (
                                    <Link
                                        onClick={() => setSendToolboxDialogOpen(true)}
                                        className={styles.repoHeaderUserLink}
                                    >
                                        <ShareIcon
                                            style={{
                                                fontSize: "1em",
                                                verticalAlign: "middle",
                                            }}
                                        />
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            style={{
                                                verticalAlign: "middle",
                                                display: "inline",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            Share
                                        </Typography>
                                    </Link>
                                )}
                                <Box className={styles.repoHeaderUser}>
                                    <LoginLinkContainerAuth0 />
                                    <Typography className={styles.repoHeaderUserText}>
                                        {user?.name}
                                    </Typography>
                                </Box>
                                <Box
                                    item
                                    style={{
                                        padding: "0",
                                    }}
                                >
                                    <Button
                                        onClick={() => setSidebarState(!sidebarState)}
                                        autoFocus
                                        disableRipple
                                        style={{
                                            fontSize: "1.5em",
                                            textTransform: "none",
                                            padding: "0",
                                            flexDirection: "column",
                                        }}
                                        sx={{
                                            color: "#3e3e3e",
                                            "&:hover": {
                                                color: "#d9d9d9",
                                            },
                                        }}
                                    >
                                        <MoreVertIcon
                                            style={{
                                                fontSize: "1.5em",
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <MenuItem onClick={handleMenuClose}>
                            <Link to={PATH_MY_TOOLBOX} className={styles.repoHeaderUserLink}>
                                <Typography fontWeight={600}>Toolbox</Typography>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose}>
                            <Link to={PATH_MY_PLAYBOOKS} className={styles.repoHeaderUserLink}>
                                <Typography fontWeight={600}>My Repository</Typography>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleLogOut}>
                            <Typography fontWeight={600}>Logout</Typography>
                        </MenuItem>
                    </Menu>
                </>
            )
        } else {
            return <LoginLinkContainerAuth0 />
        }
    }

    return (
        <header
            className={toolbox ? styles.repoHeaderContainerToolbox : styles.repoHeaderContainer}
        >
            {repoHeaderTitle}
            {renderUserMenu()}
            <Box style={{ padding: "0 64px", position: "absolute", top: "50px" }}>
                <Drawer
                    variant="permanent"
                    anchor="right"
                    style={{ display: sidebarState ? "block" : "none" }}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        ["& .MuiDrawer-paper"]: {
                            width: drawerWidth,
                            boxSizing: "border-box",
                            zIndex: "100",
                            height: "auto",
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            borderRadius: "10px",
                            top: "60px",
                            right: "225px",
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            overflow: "auto",
                        }}
                    >
                        <List>
                            <ListItem key={"Log Out"} disablePadding>
                                <ListItemButton
                                    onClick={() =>
                                        logout({
                                            returnTo: window.location.origin,
                                        })
                                    }
                                >
                                    <ListItemIcon
                                        style={{
                                            color: "#3e3e3e",
                                        }}
                                    >
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Log Out"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </header>
    )
}
