import React, { useEffect, useState } from "react"
import {
    Box,
    Typography,
    Button,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    CircularProgress,
} from "@mui/material"
import { useDispatch } from "react-redux"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import companyLogo from "../../../../../../assets/icons/Entity/company.svg"
import personLogo from "../../../../../../assets/icons/Entity/person.svg"
import rightArrow from "../../../../../../assets/icons/Entity/rightArrow.svg"
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"
import styles from "./AllEntities.module.scss"
import _ from "lodash"

export function AllEntities({
    entities,
    handleAddTools,
    onHandleSelelectEntity,
    setNewEntityId,
    refreshPageData,
    newEntityId,
}) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [newEntity, setNewEntity] = useState(null)

    useEffect(() => {
        if (newEntityId && entities.length > 0) {
            const newEntity = entities.find((entity) => entity.id === newEntityId)
            setNewEntity(newEntity)
        }
    }, [entities, newEntityId])

    const handelAddNewEntity = async () => {
        setIsLoading(true)
        try {
            // because entities are not ordered by index
            const result = await handleAddTools([31], -1, 0, null)
            let entitiesTools = []
            for (let key in result) {
                if (key in result && result[key].type === 31) {
                    entitiesTools.push(result[key])
                }
            }
            let newEntity = _.differenceBy(entitiesTools, entities, "id")
            setNewEntityId(newEntity[0].id)
            await refreshPageData()
            dispatch(handleShowToaster("success", "Successfully created new entity"))
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }
    const handleSelectEntity = (id) => {
        onHandleSelelectEntity(id)
    }
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )
    }
    return (
        <>
            <Box display="flex" flexDirection="column" paddingBottom="90px">
                <Typography fontSize="12px" fontWeight="500" color="#374957">
                    ENTITIES
                </Typography>
                <ListItem disablePadding sx={{ display: "block", marginTop: "20px" }}>
                    {entities.map(({ log, id }) => (
                        <ListItemButton
                            onClick={() => handleSelectEntity(id)}
                            key={id}
                            className={
                                newEntity?.id === id
                                    ? styles.newEntityElement
                                    : styles.entityElement
                            }
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 2,
                                    justifyContent: "center",
                                }}
                            >
                                <img src={log?.Type === "Company" ? companyLogo : personLogo} />
                            </ListItemIcon>
                            <ListItemText primary={log.Name || "Untitled entity"} />
                            <img src={rightArrow} className={styles.arrowIcon} />
                        </ListItemButton>
                    ))}
                </ListItem>
                <Box className={styles.newEntityBtnWrapper}>
                    <Box className={styles.addEntityBtn}>
                        <Button
                            onClick={handelAddNewEntity}
                            style={{ textTransform: "none", width: "100%", fontWeight: 400 }}
                            variant="standard"
                        >
                            <AddCircleOutlineRoundedIcon
                                style={{ width: "15px", height: "15px", marginRight: "10px" }}
                            />
                            Add New Entity
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
