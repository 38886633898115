import React, { useEffect, useMemo } from "react"
import stepIconActive from "../../../../../../../../assets/icons/Repo/step-icon-active.svg"
import { Box } from "@mui/material"
import { Method } from "./components/Method/Method"
import { RedFlags } from "./components/RedFlags/RedFlags"
import { useIsMobile } from "../../../../../../../../hooks/standard/useIsMobile"
import { Integrations } from "./components/Integrations/Integrations"
import styles from "./TemplateDescription.module.scss"

export function TemplateDescription({
    selectedStepData,
    selectedStepIndex,
    toolsDim,
    setValue,
    templateData,
    setToolsIdArray,
    toolsIdArray,
    toolsConfig,
    setEditMode,
    editMode,
    isAllowToEdit,
    selectedStepChildren,
    errors,
}) {
    const isMobile = useIsMobile()

    const { red_flags: redFlags, integrations, log } = selectedStepData || {}
    const { description, note } = log || {}

    const toolTypeIds = useMemo(() => {
        if (!selectedStepChildren) return []
        // Tools without special tools created by user
        return selectedStepChildren
            .filter((child) => child.tool_type_id !== 125)
            .map((child) => child.tool_type_id)
    }, [toolsIdArray, selectedStepChildren])

    const filteredTools = useMemo(() => {
        if (!toolsDim || !toolTypeIds) return []
        return toolTypeIds
            .map((typeId) => toolsDim.find((item) => item.id === typeId))
            .filter((item) => item !== undefined)
    }, [toolsDim, toolTypeIds])

    // Combine special tools with filtered tools
    const combinedTools = useMemo(() => {
        if (!filteredTools || !selectedStepChildren) return []
        const filteredToolsMap = new Map(filteredTools?.map((item) => [item.id, item]))
        const combinedToolsArray = selectedStepChildren
            ?.map((child) => {
                if (child.tool_type_id !== 125) {
                    return filteredToolsMap.get(child.tool_type_id)
                } else {
                    return { title: child.log.note }
                }
            })
            .filter((item) => item !== undefined)
        return [...combinedToolsArray]
    }, [filteredTools, selectedStepChildren])

    if (!selectedStepData) return null

    if (isMobile) {
        return (
            <Box className={styles.templateDescriptionContainerMobile}>
                <Box className={styles.templateDescriptionMainMobile}>
                    <Box className={styles.templateDescriptionDesignationsMobile}>
                        {combinedTools.length > 0 && (
                            <Method
                                methods={combinedTools}
                                toolsDim={toolsDim}
                                setValue={setValue}
                                templateData={templateData}
                                selectedStepIndex={selectedStepIndex}
                                setToolsIdArray={setToolsIdArray}
                                toolsIdArray={toolsIdArray}
                                toolsConfig={toolsConfig}
                                setEditMode={setEditMode}
                                isAllowToEdit={isAllowToEdit}
                            />
                        )}
                        {redFlags && (
                            <RedFlags
                                redFlags={redFlags}
                                isAllowToEdit={false}
                                setValue={setValue}
                                templateData={templateData}
                                selectedStepIndex={selectedStepIndex}
                                setEditMode={setEditMode}
                            />
                        )}
                        {/* {integrations && <Integrations integrations={integrations} />} */}
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box className={styles.templateDescriptionContainer}>
                <Box className={styles.templateDescriptionMain}>
                    <Box className={styles.templateDescriptionTitle}>
                        <Box className={styles.templateDescriptionTitleName}>
                            <img src={stepIconActive} />
                            <span>
                                {selectedStepIndex + 1}. {note}
                            </span>
                        </Box>
                        <Box className={styles.templateDescriptionTitleDescription}>
                            {description}
                        </Box>
                    </Box>
                    <Box className={styles.templateDescriptionDesignations}>
                        <Method
                            methods={combinedTools}
                            toolsDim={toolsDim}
                            setValue={setValue}
                            templateData={templateData}
                            selectedStepIndex={selectedStepIndex}
                            setToolsIdArray={setToolsIdArray}
                            toolsIdArray={toolsIdArray}
                            toolsConfig={toolsConfig}
                            setEditMode={setEditMode}
                            isAllowToEdit={isAllowToEdit}
                        />
                        <RedFlags
                            redFlags={redFlags}
                            isAllowToEdit={isAllowToEdit}
                            setValue={setValue}
                            templateData={templateData}
                            selectedStepIndex={selectedStepIndex}
                            setEditMode={setEditMode}
                        />
                        {integrations && <Integrations integrations={integrations} />}
                    </Box>
                </Box>
            </Box>
        )
    }
}
