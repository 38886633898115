import { Fragment } from "react"
import { useDispatch } from "react-redux"
import { changeLoaderMessage, changeLoaderState } from "../../redux/actions/loaderActions"
import useQuery from "../../hooks/standard/useQuery"

const Callback = () => {
    const dispatch = useDispatch()
    const query = useQuery()
    const code = query.get("code")

    dispatch(changeLoaderMessage("Please wait..."))
    dispatch(changeLoaderState(true))

    if (code) {
        window.opener.postMessage({ type: "oauth_code_success", code: code }, "*")
    } else {
        window.opener.postMessage({ type: "oauth_code_failure" }, "*")
    }

    return <Fragment />
}

export default Callback
