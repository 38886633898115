/** CaseReportDialog.js **/
import React, { useMemo } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Grid from "@mui/material/Grid"
import Tooltip from "@mui/material/Tooltip"
import { CaseReport } from "./CaseReport/CaseReport"
import { PDFViewer, pdf } from "@react-pdf/renderer"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"

export default function CaseReportDialog({
    open,
    setClosed,
    reportData,
    displayedReportId,
    expert = false,
    handleFinalizeReportStatus,
}) {
    const docMemo = useMemo(() => <CaseReport reportData={reportData} />, [reportData])

    const handleDownloadPDF = async () => {
        const asBlob = await pdf(docMemo).toBlob()
        const url = URL.createObjectURL(asBlob)
        const link = document.createElement("a")
        link.href = url
        link.download = `enhanced_due_diligence_report_${displayedReportId}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    // Approve & sign with true
    const handleSignAndApprove = async () => {
        if (handleFinalizeReportStatus && displayedReportId) {
            await handleFinalizeReportStatus(displayedReportId, true)
            setClosed()
        }
    }

    // Revise with false
    const handleReviseReport = async () => {
        if (handleFinalizeReportStatus && displayedReportId) {
            await handleFinalizeReportStatus(displayedReportId, false)
            setClosed()
        }
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={setClosed}
                keepMounted
                PaperProps={{
                    style: {
                        background: "rgba(30,30,30,0.65)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <DialogContent
                    style={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        height: "100vh",
                        overflow: "hidden",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            padding: "20px 40px",
                            position: "sticky",
                            top: 0,
                            background:
                                "linear-gradient(180deg, rgba(30,30,30,0.95) 0%, rgba(62,62,62,0) 80.21%)",
                            zIndex: 1000,
                        }}
                    >
                        <Grid item xs={5}>
                            <Button
                                onClick={setClosed}
                                autoFocus
                                color="secondary"
                                style={{
                                    color: "#fff",
                                    fontSize: "1.5em",
                                    textTransform: "none",
                                }}
                            >
                                <ArrowBackIcon
                                    fontSize="inherit"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        marginRight: "15px",
                                    }}
                                />
                                Enhanced Due Diligence Report
                            </Button>
                        </Grid>
                        <Grid item container xs={7} justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                {expert ? (
                                    <>
                                        {/* Red "Revise Report" button (no hover color change) */}
                                        <Button
                                            onClick={handleReviseReport}
                                            variant="contained"
                                            sx={{
                                                fontSize: "1.2em",
                                                textTransform: "none",
                                                backgroundColor: "red",
                                                "&:hover": {
                                                    backgroundColor: "red",
                                                },
                                                marginRight: "20px",
                                            }}
                                        >
                                            Revise Report
                                        </Button>

                                        {/* Green "Sign and Approve" button (no hover color change) */}
                                        <Button
                                            onClick={handleSignAndApprove}
                                            variant="contained"
                                            sx={{
                                                fontSize: "1.2em",
                                                textTransform: "none",
                                                backgroundColor: "#4CAF50",
                                                "&:hover": {
                                                    backgroundColor: "#4CAF50",
                                                },
                                            }}
                                        >
                                            Sign and Approve
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={handleDownloadPDF}
                                        autoFocus
                                        color="secondary"
                                        style={{
                                            color: "#fff",
                                            fontSize: "1.5em",
                                            textTransform: "none",
                                            padding: "6px 8px 0 8px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Tooltip title="Download Report">
                                            <PictureAsPdfIcon
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "1.5em",
                                                }}
                                            />
                                        </Tooltip>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{
                            flexGrow: 1,
                            width: "50vw",
                            margin: "0 auto",
                            padding: 0,
                        }}
                    >
                        <PDFViewer
                            style={{
                                width: "100%",
                                height: "100%",
                                border: "none",
                                margin: 0,
                                padding: 0,
                            }}
                            showToolbar={false}
                        >
                            {docMemo}
                        </PDFViewer>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}
