import React from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material"

const FilesAttachments = ({ open, onClose, caseId, files = [] }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Files for Case ID {caseId}</DialogTitle>
            <DialogContent>
                {files && files.length > 0 ? (
                    <List>
                        {files.map((file, index) => (
                            <ListItem key={index} disableGutters>
                                <ListItemButton
                                    component="a"
                                    href={file.view_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ListItemText primary={file.file_name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>No files found.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilesAttachments
