import React, { useEffect, useState } from "react"
import { uuidv4, capitalizeFirstLetter } from "../../../helpers/standardHelpers"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import { Checkbox, FormControlLabel, Autocomplete, Grid, Box } from "@mui/material"
import { Controller } from "react-hook-form"
import { useAuth0 } from "@auth0/auth0-react"
import { uploadToS3 } from "../../../hooks/standard/uploadFile"
import { useSelector, useDispatch } from "react-redux"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

const useStyles = makeStyles(() => ({
    input: {
        height: 42,
        // width: "440px",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    textArea: {
        // width: "440px",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    tagsInput: {
        "& .tags-input": {
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            width: "100%",
            borderColor: "rgba(217, 217, 217, 1)",
            minHeight: "42px",
            padding: "0 14px",
            display: "flex",
            alignItems: "center",
            "& .tagify": {
                border: "none",
                outline: "none",
                maxWidth: "100%",
            },
        },
    },
    label: {
        fontSize: "20px",
        color: "#000",
    },
    stepDot: {
        width: "16px",
        height: "16px",
        borderRadius: "100%",
        zIndex: "10",
    },
    stepLine: {
        width: "44px",
        height: "2px",
        backgroundColor: "#E1E1E1",
    },
    stepper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "136px",
        position: "relative",
    },
    stepProgress: {
        backgroundColor: "#E1E1E1",
        position: "absolute",
        top: "50%",
        left: "0",
        transform: "translateY(-50%)",
        height: "2px",
        width: "100%",
        zIndex: "0",
        transition: ".4s ease",
    },
}))

const CaseInformationMainPage = ({ values, setValue, control, caseTypes, setUploadingFile }) => {
    const classes = useStyles()
    const [templates, setTemplates] = useState([{ id: -1, name: "None" }])
    const [chosenTemplate, setChosenTemplate] = useState({})
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    useEffect(() => {
        var allTemplates = caseTypes
            .map((caseType) => {
                const ret = caseType.templates.map((template) => {
                    const ret = {
                        ...template,
                        case_type_id: caseType.id,
                        case_type_name: caseType.name,
                    }
                    return ret
                })
                return ret
            })
            .flat(1)
        setTemplates([{ id: -1, name: "None" }].concat(allTemplates))
    }, [caseTypes])

    useEffect(() => {
        if (values?.template_id) {
            let currChosenTemplate = templates.filter((obj) => {
                return obj.id === values.template_id
            })[0]
            setValue("template_fields", null)
            setChosenTemplate(currChosenTemplate)
            setValue(
                "type",
                currChosenTemplate?.case_type_id ? currChosenTemplate.case_type_id : null,
            )
        } else {
            setValue("type", null)
        }
    }, [values.template_id])

    const getInputField = (item) => {
        if (item?.type) {
            if (item.type === "select") {
                return (
                    <Controller
                        name={`template_fields.${item.field}`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <TextField
                                disabled={values?.template_fields?.case_list_file}
                                select
                                {...field}
                                variant="outlined"
                                label={capitalizeFirstLetter(item.field.replace("_", " "))}
                                InputProps={{
                                    className: classes.input,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            >
                                {item.options.map((itemOption) => {
                                    return (
                                        <MenuItem key={itemOption.value} value={itemOption.value}>
                                            {itemOption.display}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        )}
                    />
                )
            }
            if (item.type === "select-autocomplete") {
                return (
                    <Controller
                        control={control}
                        name={`template_fields.${item.field}`}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                disabled={values?.template_fields?.case_list_file}
                                // freeSolo
                                autoSelect
                                options={item.options.map((item) => item.display)}
                                onChange={(event, values) => onChange(values)}
                                value={value}
                                style={{ marginBottom: "20px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="All"
                                        label={item.field}
                                        variant="outlined"
                                        onChange={onChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                )
            }
            if (item.type === "checkbox") {
                return (
                    <Controller
                        name={`template_fields.${item.field}`}
                        defaultValue={true}
                        control={control}
                        render={({ field: props }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={values?.template_fields?.case_list_file}
                                        {...props}
                                        checked={props.value}
                                        onChange={(e) => props.onChange(e.target.checked)}
                                    />
                                }
                                label={item.field}
                            />
                        )}
                    />
                )
            }
        } else {
            return (
                <Controller
                    name={`template_fields.${item.field}`}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <TextField
                            disabled={values?.template_fields?.case_list_file}
                            {...field}
                            variant="outlined"
                            label={capitalizeFirstLetter(item.field.replace("_", " "))}
                            InputProps={{
                                className: classes.input,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    )}
                />
            )
        }
    }

    const getReadyInputFields = () => {
        if (chosenTemplate && chosenTemplate?.list_of_fields.length > 0) {
            const fieldsWrapped = chosenTemplate.list_of_fields.map((item, index) => (
                <Grid key={index} item style={{ width: "300px" }}>
                    {getInputField(item)}
                </Grid>
            ))
            var readyToDisplay = []
            const chunkSize = 4
            for (let i = 0; i < fieldsWrapped.length; i += chunkSize) {
                const chunk = fieldsWrapped.slice(i, i + chunkSize)
                // do whatever
                readyToDisplay.push(
                    <Grid item container spacing={3}>
                        {chunk}
                    </Grid>,
                )
            }
            return readyToDisplay
        }
    }

    // upload csv file to S3
    const handleFileUpload = async (single_file_list) => {
        try {
            setUploadingFile(true)
            var file = single_file_list[0]
            const response = await uploadToS3(
                "case_list",
                file,
                // force file name
                `case_list_${uuidv4().slice(0, 8)}` + file.name.slice(file.name.indexOf(".")),
                // public_file
                false,
                dispatch,
                refreshAccessTokenCounter,
            )
            const file_address = response.config.url.slice(0, response.config.url.indexOf("?"))
            setValue("template_fields.case_list_file", file_address)
        } finally {
            setUploadingFile(false)
        }
    }

    return (
        <Box
            xs={12}
            sx={{
                background: "#f3f3f3",
                padding: "28px 0px",
                borderRadius: "10px",
                width: "100%",
            }}
        >
            <Accordion
                defaultExpanded
                style={{
                    backgroundColor: "#f3f3f3",
                    boxShadow: "none",
                    borderRadius: "0",
                }}
            >
                <AccordionDetails>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "62.5px 62.5px repeat(10, 1fr)",
                            gap: "8px",
                            rowGap: "20px",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            item
                            sx={{
                                gridColumn: "1/3",
                            }}
                        >
                            <Typography
                                component="label"
                                variant="body1"
                                color="#3E3E3E"
                                marginRight="0px"
                                marginBottom={{ xs: "18px", sm: "0" }}
                            >
                                Description:
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                gridColumn: "3/13",
                            }}
                            marginRight={{ xs: "0", sm: "0" }}
                        >
                            <Controller
                                name="description"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={3}
                                        variant="outlined"
                                        id="description"
                                        // label="Type"
                                        InputProps={{
                                            className: classes.textArea,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                        {templates && templates.length > 1 && (
                            <>
                                <Box
                                    sx={{
                                        gridColumn: "1/3",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            gridColumn: "1/3",
                                        }}
                                    >
                                        <Typography
                                            component="label"
                                            variant="body1"
                                            color="#3E3E3E"
                                            marginBottom={{ xs: "18px", sm: "0" }}
                                        >
                                            Run Template:
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: "3/7",
                                        "@media (max-width: 1200px)": {
                                            gridColumn: "3/13",
                                        },
                                    }}
                                >
                                    <Controller
                                        name="template_id"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                {...field}
                                                variant="outlined"
                                                // label="Type"
                                                InputProps={{
                                                    className: classes.input,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            >
                                                {templates.map((template) => {
                                                    return (
                                                        <MenuItem
                                                            key={template?.id}
                                                            value={template?.id}
                                                        >
                                                            {template.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        )}
                                    />
                                </Box>
                            </>
                        )}
                        {values?.template_id && values?.template_id != -1 && (
                            <>
                                <Box
                                    sx={{
                                        gridColumn: "8/9",
                                        "@media (max-width: 1200px)": {
                                            gridColumn: "1/3",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            gridColumn: "1/3",
                                        }}
                                    >
                                        <Typography
                                            component="label"
                                            variant="body1"
                                            color="#3E3E3E"
                                            marginBottom={{
                                                xs: "18px",
                                                sm: "0",
                                            }}
                                        >
                                            Type:
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: "9/13",
                                        "@media (max-width: 1200px)": {
                                            gridColumn: "3/13",
                                        },
                                    }}
                                >
                                    {chosenTemplate?.case_type_name}
                                </Box>
                            </>
                        )}
                        {chosenTemplate && chosenTemplate?.list_of_fields?.length > 0 && (
                            <>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    width="800px"
                                    marginTop="10px"
                                >
                                    <Box sx={{ width: "250px" }}>
                                        <Typography
                                            component="label"
                                            variant="body1"
                                            color="#3E3E3E"
                                            marginRight="0px"
                                        >
                                            Template Fields:
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                        <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                                            {getReadyInputFields()}
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        gridColumn: "1/3",
                                        display: "flex",
                                        width: "800px",
                                    }}
                                >
                                    <Box sx={{ width: "150px" }}>
                                        <Typography
                                            component="label"
                                            variant="body1"
                                            color="#3E3E3E"
                                            marginRight="0px"
                                        >
                                            Template CSV\Excel File:
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                        <Box>
                                            <input
                                                // ref={register}
                                                type="file"
                                                name="template_file"
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                style={{
                                                    background: "none",
                                                    padding: "0",
                                                }}
                                                onChange={(event) => {
                                                    handleFileUpload(event.currentTarget.files)
                                                }}
                                            />
                                            {values?.template_fields?.case_list_file && (
                                                <IconButton
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "transparent",
                                                        boxShadow: "none",
                                                        padding: "0 0 0 8px",
                                                        transform: "scale(0.9)",
                                                        ml: 1,
                                                        "&.MuiButtonBase-root:hover": {
                                                            bgcolor: "transparent",
                                                        },
                                                        visibility: "visible",
                                                        // not sure its working properly
                                                        transition:
                                                            "visibility 0s linear 300ms, opacity 50ms",
                                                    }}
                                                    onClick={() => {
                                                        setValue(
                                                            "template_fields.case_list_file",
                                                            null,
                                                        )
                                                        setValue("template_file", null)
                                                    }}
                                                    size="xs"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default CaseInformationMainPage
